<template>
  <section>
    <div class="politics-privacy">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h5 class="cemex-concept-title">
              <strong
                >POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES DE LA
                APLICACIÓN LA VILLA COUNTRY CLUB DE LA VILLA COUNTRY ASOCIACIÓN
                PERÚ</strong
              >
            </h5>
            <div class="cemex-concept-box">
              <p>
                De conformidad con la Ley No. 29733, Ley de Protección de Datos
                Personales, y su Reglamento aprobado mediante Decreto Supremo
                No. 003-2013-JUS, informamos que La Villa Country Asociación
                Perú. (en adelante, “LA VILLA COUNTRY CLUB”) será responsable de
                recabar sus datos personales, del uso que se le dé a los mismos
                y de su protección. En particular, LA VILLA COUNTRY CLUB, desea
                informar a todos sus clientes o interesado acerca de su Política
                de Protección de datos personales, para que estos determinen
                libre y voluntariamente si desean facilitar a LA VILLA COUNTRY
                CLUB los datos personales que se les puedan requerir o que se
                puedan obtener por el uso de la Plataforma “LA VILLA COUNTRY
                CLUB” (la “Aplicación”)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="object-purpose">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="cemex-concept-box">
              <p class="responsive-title">
                <strong
                  >1. <span class="margenes-title"></span>OBJETO Y FINALIDAD DE
                  LA RECOLECCIÓN DE DATOS PERSONALES</strong
                >
              </p>
              <p>
                Sus datos personales serán recopilados para las siguientes
                finalidades relacionadas con el uso de la Aplicación, siendo
                necesaria para su funcionamiento y uso de los servicios que
                ofrece: registro dentro de la aplicación móvil, generación de
                usuario y contraseña; envío de información relativa a su cuenta;
                prestar, gestionar, administrar, ampliar y mejorar los
                contenidos ofrecidos en la Aplicación; atender adecuadamente
                cualquier consulta; remitir información sobre los servicios o
                productos de LA VILLA COUNTRY CLUB que pudieran ser de interés
                de los Usuarios; y, en general, para dar cumplimiento a las
                obligaciones legales como titulares de la Aplicación.
              </p>
              <p>
                Las finalidades adicionales para las cuales LA VILLA COUNTRY
                CLUB solicita el consentimiento expreso del Usuario son las
                siguientes: envío de publicidad, y reportes de los servicios
                ofrecidos por LA VILLA COUNTRY CLUB a mi Nº de celular, correo
                electrónico u otro medio de comunicación brindado LA VILLA
                COUNTRY CLUB
              </p>
              <p>
                Los datos personales que se recopilen de los usuarios de la
                Aplicación no se harán públicos ni se comercializarán a
                terceros. En todo caso, serán transferidos a los terceros que
                comercializan los productos por medio de la Aplicación, para la
                correcta prestación de los servicios de la misma. Asimismo, los
                datos personales no serán revelados a las autoridades públicas o
                de gobierno, excepto en los casos que LA VILLA COUNTRY CLUB esté
                o estuviere obligada por la ley, por las autoridades
                gubernamentales o por orden judicial a compartir dichos datos
                personales. LA VILLA COUNTRY CLUB conservará los datos
                personales recopilados mediante esta Aplicación por tiempo
                indeterminado, inclusive una vez que se cancele la suscripción
                de los Usuarios.
              </p>
              <p>
                Los servidores, ordenadores y/o cualquier centro de
                procesamiento de datos utilizados por la LA VILLA COUNTRY CLUB
                pueden estar ubicados fuera del país. En estos casos, de ser
                aplicable, LA VILLA COUNTRY CLUB trata los datos personales de
                los Usuarios (almacenamiento, procesamiento y conservación) a
                nivel internacional (“flujo transfronterizo”) según la normativa
                sobre protección de datos personales.
              </p>
              <p>
                LA VILLA COUNTRY CLUB garantiza la confidencialidad de los datos
                de carácter personal facilitados por los Usuarios, su
                tratamiento de acuerdo con la legislación vigente sobre
                protección de datos personales, habiendo adoptado los niveles de
                seguridad de protección legalmente requeridos, e instalados
                todos los medios y medidas técnicas a su alcance para evitar la
                pérdida, mal uso, alteración, acceso no autorizado y robo de la
                información. A pesar de ello, los Usuarios deben ser conscientes
                de que las medidas de seguridad en internet no son
                inexpugnables.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="use-cookies">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="cemex-concept-box">
              <p class="responsive-title">
                <strong
                  >2. <span class="margenes-title"></span>USO DE COOKIES</strong
                >
              </p>
              <p>
                LA VILLA COUNTRY CLUB puede utilizar cookies (ficheros enviados
                a un navegador por medio de un servidor web para registrar las
                actividades de los Usuarios en la Aplicación cuando los usuarios
                utilicen la aplicación).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="minor">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="cemex-concept-box">
              <p class="responsive-title">
                <strong
                  >3. <span class="margenes-title"></span>MENORES DE 18
                  AÑOS</strong
                >
              </p>
              <p>
                La Aplicación está dirigida exclusivamente a usuarios mayores de
                18 años, estando por tanto restringida la entrada de menores de
                18 años al mismo. LA VILLA COUNTRY CLUB se reserva el derecho de
                verificar, por los medios que considere más oportunos, la edad
                real de cualquier Usuario.
              </p>
              <p>
                Bajo sospecha de que un usuario de la Aplicación es menor de 18
                años, y de que ha falseado los datos que se requieren para su
                acceso, LA VILLA COUNTRY CLUB podrá denegar al referido usuario
                el acceso a los servicios ofrecidos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="services-third-parties">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="cemex-concept-box">
              <p class="responsive-title">
                <strong
                  >4. <span class="margenes-title"></span>PRESTACIÓN DE
                  SERVICIOS POR PARTE DE TERCEROS</strong
                >
              </p>
              <p>
                En caso de que la información recopilada requiera ser compartida
                con socios comerciales o empresas vinculadas a LA VILLA COUNTRY
                CLUB, los Usuarios serán informados previamente de ello y sólo
                se compartirá en la medida necesaria y admitida en derecho.
                Asimismo, LA VILLA COUNTRY CLUB puede trabajar con terceros que
                proveen servicios a ésta en relación con la Aplicación. Dichas
                empresas podrán tener acceso a los datos personales de los
                Usuarios, los cuales serán utilizados para la ejecución de los
                servicios prestados por la Aplicación y de acuerdo con la
                normativa sobre protección de datos personales. Los Usuarios
                autorizan expresamente la transferencia de sus datos personales
                a dichos terceros, con las finalidades anteriormente indicadas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="safety">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="cemex-concept-box">
              <p class="responsive-title">
                <strong
                  >5. <span class="margenes-title"></span>SEGURIDAD</strong
                >
              </p>
              <p>
                LA VILLA COUNTRY CLUB manifiesta y garantiza que realizará en
                todo momento sus mejores esfuerzos para mantener la seguridad y
                confidencialidad de los datos personales y que continuará
                mejorando las medidas de seguridad adoptadas en línea con
                cualesquiera desarrollos legales y tecnológicos. En particular,
                LA VILLA COUNTRY CLUB garantiza que ha adoptado las medidas de
                seguridad exigidas con arreglo a legislación peruana de datos
                personales.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-rights">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="cemex-concept-box">
              <p class="responsive-title">
                <strong
                  >6. <span class="margenes-title"></span>DERECHOS DE LOS
                  USUARIOS</strong
                >
              </p>
              <p>
                La actualización de los datos personales, así como los derechos
                de información, acceso, rectificación cancelación y oposición se
                pueden ejercitar mediante comunicación por correo electrónico a
                <strong class="responsive-enlaze-fonst"
                  >countryclublavilla@gmail.com</strong
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="use-personal-data">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="cemex-concept-box">
              <p class="responsive-title">
                <strong
                  >7. <span class="margenes-title"></span>CONSENTIMIENTO DE USO
                  DE DATOS PERSONALES</strong
                >
              </p>
              <p>
                El Usuario Autoriza a que sus datos personales sean incorporados
                y tratados en el banco de datos de la LA VILLA COUNTRY CLUB para
                los fines y en las condiciones expuestas en la presente
                política.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "privacy-protection-policies",
};
</script>

<style lang="scss" scoped>
.politics-privacy {
  padding: 3rem 0 1rem 0;
}

@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
  margin-right: auto;
  margin-left: auto;
}

@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter / 2;
  margin-left: -$gutter / 2;
}

@mixin make-container-max-widths(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
  @include deprecate("The `make-container-max-widths` mixin", "v4.5.2", "v5");
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; // Reset earlier grid tiers
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}

@mixin row-cols($count) {
  > * {
    flex: 0 0 100% / $count;
    max-width: 100% / $count;
  }
}

.cemex-concept-title {
  text-decoration: underline;
  text-align: center;
}

.cemex-concept-box {
  padding: 1rem 0 1rem 0;
  text-align: justify;
}

/*.cemex-concept-box p {
    margin-bottom: 2rem;
}*/

.accommodate-image {
  padding: 2rem 0;
  text-align: right;
}

.box-flex {
  display: flex;
}

.space-text {
  margin-left: 1rem;
}

.line-black {
  width: 25%;
  height: 2px;
  background: black;
}

.margenes-title {
  margin: 0 10px;
}

.font-small {
  font-size: 13px;
  margin-top: 0.8rem;
}

.font-small-margin {
  margin: 3rem 0;
  font-size: 13px;
}

.politics-privacy {
  padding: 3rem 0 1rem 0;
}

@media only screen and (max-width: 480px) {
  .margenes-title {
    margin: auto;
  }

  .responsive-title {
    text-align: center;
  }

  .accommodate-image {
    text-align: center;
  }

  .responsive-enlaze-fonst {
    font-size: 14px;
  }
}

h5,
h5 {
  font-size: 1.25rem;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
</style>

